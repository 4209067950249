import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import axios from "axios";

import { useSelector } from "react-redux";

import Board from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";

const Pipeline = () => {
  const userStatus = useSelector((state) => state.auth.status);

  const [editingColumnId, setEditingColumnId] = useState(null);
  const [newColumnTitle, setNewColumnTitle] = useState("");
  const [allowAddColumnState, setAllowAddColumnState] = useState({
    on: "right",
  });
  const [leads, setLeads] = useState(false);

  const [board, setBoard] = useState(false);

  // get pipeline after user app is initiated
  useEffect(() => {
    if (userStatus === "succeeded") {
      const fetchLeads = async () => {
        let response = await axios.get("/api/current_user");
        console.log("user: ", response);
        setBoard(response.data.pipeline);
      };
      fetchLeads();
    }
  }, [userStatus]);

  const onNewCard = (draftCard) => ({
    id: new Date().getTime(),
    ...draftCard,
  });

  const onNewColumn = (draftColumn) => {
    const newColumn = {
      id: new Date().getTime(),
      ...draftColumn,
    };
    setBoard((prevBoard) => ({
      ...prevBoard,
      columns: [...prevBoard.columns, newColumn],
    }));
    setNewColumnTitle(""); // Reset the newColumnTitle state when adding a new column
    return newColumn; // Return the new column object
  };

  const onNewColumnConfirm = (draftColumn) => {
    // if (board.columns.length >= 6) {
    //   alert("You cannot add more than 6 columns.");
    //   return null;
    // }
    return onNewColumn(draftColumn);
  };

  const handleEditColumn = (columnId) => {
    const columnIndex = board.columns.findIndex(
      (column) => column.id === columnId
    );

    if (columnIndex !== -1) {
      const updatedColumns = [...board.columns];
      updatedColumns[columnIndex].title = newColumnTitle;
      setBoard({
        ...board,
        columns: updatedColumns,
      });
      setEditingColumnId(null);
      setNewColumnTitle(""); // Reset the newColumnTitle state after saving
    }
  };

  useEffect(() => {
    if (board) {
      // Update allowAddColumn based on the length of columns in the board
      const allowAddColumn = board.columns.length < 6 ? { on: "right" } : null;

      // Update the state with the new allowAddColumn value
      setAllowAddColumnState(allowAddColumn);
    }
  }, [board]);

  const renderColumnHeader = (column, { removeColumn }) => (
    <div className="drag_column_header">
      {editingColumnId === column.id ? (
        <div>
          <input
            type="text"
            value={newColumnTitle}
            onChange={(e) => setNewColumnTitle(e.target.value)}
          />
          <button onClick={() => handleEditColumn(column.id)}>Save</button>
        </div>
      ) : (
        <div>
          <h3>
            <div>
              {column.title}
              <button onClick={() => setEditingColumnId(column.id)}>
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1724_9775)">
                    <path
                      d="M7.08301 1.24994C7.18912 1.12454 7.32039 1.02246 7.46843 0.950224C7.61647 0.877984 7.77805 0.837163 7.94286 0.830362C8.10767 0.823562 8.2721 0.850931 8.42567 0.910726C8.57924 0.970521 8.71858 1.06143 8.8348 1.17766C8.95103 1.29388 9.0416 1.43288 9.10073 1.58579C9.15987 1.7387 9.18627 1.90216 9.17826 2.06576C9.17025 2.22937 9.12801 2.38952 9.05422 2.53601C8.98042 2.6825 8.87671 2.81212 8.74967 2.91661L3.12467 8.54161L0.833008 9.16661L1.45801 6.87494L7.08301 1.24994Z"
                      stroke="#9A9A9A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M6.25 2.08325L7.91667 3.74992"
                      stroke="#9A9A9A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1724_9775">
                      <rect width="10" height="10" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <button
              onClick={() => {
                removeColumn(column.id);
                setAllowAddColumnState({ on: "right" });
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4H14"
                  stroke="#2196BB"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M12.6663 4V13.3333C12.6663 14 11.9997 14.6667 11.333 14.6667H4.66634C3.99967 14.6667 3.33301 14 3.33301 13.3333V4"
                  stroke="#2196BB"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M5.33301 3.99992V2.66659C5.33301 1.99992 5.99967 1.33325 6.66634 1.33325H9.33301C9.99967 1.33325 10.6663 1.99992 10.6663 2.66659V3.99992"
                  stroke="#2196BB"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M6.66699 7.33325V11.3333"
                  stroke="#2196BB"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M9.33301 7.33325V11.3333"
                  stroke="#2196BB"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>
          </h3>
          <p>28 leads</p>
        </div>
      )}
    </div>
  );

  const updatePipeline = async (pipeline) => {
    const response = await axios.post("/api/update-pipeline", { pipeline });
    console.log("pipeline updated: ", response);
    setBoard(response.data.pipeline);
  };

  // useEffect(() => {
  //   if (userStatus == "succeeded") {
  //     setBoard(currUser.user.pipeline);
  //   }
  // }, [userStatus]);

  // useEffect(() => {
  //   const fetchLeads = async () => {
  //     let leads = await axios.get("/api/leads");
  //     console.log("leads: ", leads);

  //     let initialColumn = [];
  //     leads.data.map((lead, idx) => {
  //       initialColumn.push({
  //         id: idx,
  //         title: lead.name,
  //         description: lead.phone,
  //       });
  //     });

  //     // setBoard({
  //     //   columns: [
  //     //     {
  //     //       id: 1,
  //     //       title: "New Column",
  //     //       cards: [
  //     //         { id: 1, title: "this", description: "these" },
  //     //         { id: 2, title: "for", description: "for" },
  //     //         { id: 3, title: "that", description: "those" },
  //     //       ],
  //     //     },
  //     //     {
  //     //       id: 2,
  //     //       title: "Another One",
  //     //       cards: [
  //     //         { id: 4, title: "dust", description: "and another one gone" },
  //     //       ],
  //     //     },
  //     //     { id: 3, title: "Interaction Initiated", cards: [] },
  //     //   ],
  //     // });
  //     setBoard({
  //       columns: [
  //         {
  //           id: 1,
  //           title: "Broadcast Sent",
  //           cards: initialColumn,
  //         },
  //         {
  //           id: 2,
  //           title: "Message Replied",
  //           cards: [
  //             { id: 4, title: "dust", description: "and another one gone" },
  //           ],
  //         },
  //         { id: 3, title: "Call/Meeting Requested", cards: [] },
  //         { id: 4, title: "Call/Meeting Booked", cards: [] },
  //       ],
  //     });

  //     setLeads(leads.data);
  //   };

  //   fetchLeads();
  // }, []);

  console.log("board state: ", board);

  return (
    <div className="flex">
      <Sidebar title={"Pipeline"} />
      <div className="main-container">
        <Header />
        {board ? (
          <Board
            initialBoard={board}
            // children={board}
            // allowAddCard={{ on: "top" }}
            allowAddColumn={allowAddColumnState}
            allowRemoveCard
            allowRenameColumn
            allowRemoveColumn
            onCardNew={() => {
              console.log("new card");
            }}
            onCardRemove={() => {
              console.log("removed card");
              updatePipeline(board);
            }}
            onCardDragEnd={(board) => {
              console.log("dragged!", board);
              updatePipeline(board);
            }}
            onColumnNew={(board) => {
              console.log("column added!", board);
              updatePipeline(board);
            }}
            onColumnRemove={(board) => {
              console.log("column removed!", board);
              updatePipeline(board);
            }}
            onColumnRename={(board) => {
              console.log("column renamed!", board);
              updatePipeline(board);
            }}
            onColumnDragEnd={(board) => {
              console.log("column dragged!", board);
              updatePipeline(board);
            }}
            onNewCardConfirm={onNewCard}
            onNewColumnConfirm={onNewColumnConfirm}
            // renderColumnHeader={renderColumnHeader}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Pipeline;
